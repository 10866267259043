import React, { useEffect, useRef } from 'react';

const InactivityHandler = () => {
    const timeoutRef = useRef(null);

    const logoutUser = () => {
        window.location.href='/';
    };

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(logoutUser, 5 * 60 * 1000);//5minutes
    };

    useEffect(() => {
        const events = ['mousemove', 'keydown', 'scroll', 'click'];
        events.forEach(event => window.addEventListener(event, resetTimeout));

        resetTimeout();

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            events.forEach(event => window.removeEventListener(event, resetTimeout));
        };
    }, []);

    return null;
};

export default InactivityHandler;
