import React, {useEffect, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import articleImg from "../../img/BMD-3398-760x400.png";
import {useSelector} from "react-redux";
import {useTitle} from "../../../utils/Utils";

function ListArticles() {
    const {name} = useParams();
    useTitle("Articles");
    const [articles, setArticles] = useState([]);
    const [data, setData] = useState(null);
    const {user, accessToken} = useSelector(state => state.authUser);
    const fetchArticle = ()=>{
        let preloader = document.getElementById('preloader');
        preloader.classList.remove("preloader-deactivate");
        let dataList = ['SANTE_SEXUELLE','GROSSESSE_PRECOSE','HYGENE_MENSTRUELLE','VIH_IST','DROGUE_STUPEFIANT','VVG','IVG','PLANNING_FAMILLIAL','YOUNGLEAD'];
        if(dataList.fill(name).length<=0){
            window.location.href='/home/meeting'
        }else{
            for (let i = 0; i < dataList.length; i++) {
                switch (dataList[i]) {
                    case 'SANTE_SEXUELLE': {
                        setData({name: 'SANTE_SEXUELLE', description: 'Santé Sexuelle & Reproductrice'});
                        break;
                    }
                    case 'GROSSESSE_PRECOSE': {
                        setData({name: 'GROSSESSE_PRECOSE', description: 'Grossésses Précosse'});
                        break;
                    }
                    case 'HYGENE_MENSTRUELLE': {
                        setData({name: 'HYGENE_MENSTRUELLE', description: 'Hygiéne Menstruelle'});
                        break;
                    }
                    case 'VIH_IST': {
                        setData({name: 'VIH_IST', description: 'IST & VIH|SIDA'});
                        break;
                    }
                    case 'DROGUE_STUPEFIANT': {
                        setData({name: 'DROGUE_STUPEFIANT', description: 'Drogue et Stupéfiant'});
                        break;
                    }
                    case 'VVG': {
                        setData({name: 'VVG', description: 'Violences Bases sur le Genre'});
                        break;
                    }
                    case 'IVG': {
                        setData({name: 'IVG', description: 'Avortement Sécurisée'});
                        break;
                    }
                    case 'PLANNING_FAMILLIAL': {
                        setData({name: 'PLANNING_FAMILLIAL', description: 'Planning Familialle | Contraception'});
                        break;
                    }
                    case 'YOUNGLEAD': {
                        setData({name: 'YOUNGLEAD', description: 'LeaderShip Jeune'});
                        break;
                    }
                    default: {
                        setData(null);
                        break;
                    }

                }
            }
            axiosInstance().get('api/article/get-by-categorie/'+ name)
                .then(function (response) {
                    let data = response.data;
                    let fetchedArticles = [];
                    if (data != null) {
                        for (let i = 0; i < data.length; i++) {
                            try {
                                fetchedArticles.push(
                                    <div className="col-lg-4 col-md-6 col-12" key={data[i].id}>
                                        <div className="card">
                                            {data[i].phote}
                                            <img src={data[i].phote == null ? articleImg : data[i].phote} className="card-img-top" alt="" />
                                            <div className="card-img-overlay">
                                                <a className="btn btn-primary">Auteur: {data[i].author}</a>
                                                <h2 className="card-title">Titre: {data[i].title}</h2>
                                                <p className="card-title"><NavLink to={"/home/read/" + data[i].id}>Lire</NavLink></p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            } catch (e) {
                            }
                        }
                        setArticles(fetchedArticles);
                        preloader.classList.add("preloader-deactivate");
                    } else {
                        fetchedArticles.push(
                            <div id="error">
                                <p>Il n'y a pas d'articles pour le moment</p>
                            </div>
                        );
                        setArticles(fetchedArticles);
                        preloader.classList.add("preloader-deactivate");
                    }
                })
                .catch(function (error) {
                    preloader.classList.add("preloader-deactivate");
                });
        }

    }
    function checkIfUserIsConnect() {
        window.location='/';
    }
    useEffect(
        ()=>{
            if(user===null)
                checkIfUserIsConnect();
            else
                fetchArticle();
        },[]
    )
    return <main id="main" className="main">
        <div className="pagetitle">
            <h1>Articles</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">{data != null && data.description}</li>
                </ol>
            </nav>
        </div>
        <section className="section">
            <div className="row align-items-top">
                {
                    (articles.length === 0 ? (<></>) : articles)
                }
            </div>
        </section>

    </main>;

}

export default ListArticles;