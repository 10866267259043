import React, {useEffect, useState} from "react";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {useSelector} from "react-redux";
import {isUserConnect, useTitle} from "../../utils/Utils";
import PDFGenerator from "../pdf/PDFGenerator";
import InvoicePDF from "../pdf/InvoicePDF";
import {Button, Select} from "antd";

export default function Meeting() {
    const [rdv, setRdv]=useState([]);
    const [loading, setLoading]=useState(true);
    useTitle("Rendez-vous");
    isUserConnect();
    const {user, accessToken} = useSelector(state => state.authUser);

    function checkIfUserIsConnect() {
            window.location='/';
    }
    const rdvStatus = (status)=>{
        let className = 'badge ';
        if(status==='PRIS') {
            className += 'bg-warning';
        } else if(status==='HONNERE') {
            className += 'bg-success';
        } else if(status==='ANNULLER') {
            className += 'bg-danger';
        } else if(status==='ENCOURS') {
            className += 'bg-info'
        }
        return className;
    };
    const dateFormat = (value)=>{
        let date = new Date(value);
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let year = date.getFullYear();
        let formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    }

    const annuller = (id)=>{
        setLoading(true);
        axiosInstance(accessToken).put('rendezvous/update/annuller/'+id+"/"+user.id)
            .then(function (response) {
                window.location.reload();
            })
            .catch(function (error) {
                setLoading(false);
            });
    }

    const fetchRdv = ()=>{
        setLoading(true);
        axiosInstance(accessToken).get('rendezvous/getByuserId/'+ user.id)
            .then(function (response) {
                if(response.data.length <=0){
                    setRdv([]);
                }else {
                    setRdv(response.data);
                }
                setLoading(false);
            })
            .catch(function (error) {
                setRdv([]);
                setLoading(false);
            });
    }

    useEffect(
        ()=>{
            if(user===null)
                checkIfUserIsConnect();
            else
                fetchRdv();
        },[]
    );
    return <main id="main" className="main">
        <div className="pagetitle">
            <h1>Mes Rendez-vous</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active">Mes Rendez-vous</li>
                </ol>
            </nav>
        </div>
        <section className="section dashboard">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="card recent-sales overflow-auto">
                                <div className="card-body">
                                    <h5 className="card-title">Liste</h5>
                                    {
                                        rdv.length > 0 && <table className="table table-borderless">
                                            <thead>
                                            <tr key={-1}>
                                                <th scope="col">#</th>
                                                <th scope="col">Libelle</th>
                                                <th scope="col">Service</th>
                                                <th scope="col">USRAJ</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Heure</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Print</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                            </thead>
                                            <tbody id="table">
                                            {
                                                rdv.map((value, index) => {

                                                    return <tr key={(index+1)+'tr'}>
                                                        <th scope="row" key={(index+1)+'th'}>
                                                            <a className="icon show" aria-expanded="true">{index+1}</a>
                                                        </th>
                                                        <td key={(index+1)+'libelle'}>{value.libelle}</td>
                                                        <td key={(index+1)+'td.libelle.serv'}><a className="text-primary">{value.serviceEntity?.libelle}</a></td>
                                                        <td key={(index+1)+'td.nom'}><a className="text-primary">{value.centrePartenaire.nom}</a></td>
                                                        <td key={(index+1)+'td.rdv.date'}><a className="text-primary">{dateFormat(value.dateRdv)}</a></td>
                                                        <td key={(index+1)+'td.heure'}><a className="text-primary">{value.heureDebut}</a></td>
                                                        <td key={(index+1)+'td.rdv'}><span className={rdvStatus(value.rdv)}>{value.rdv}</span></td>
                                                        <td key={(index+1)+'td.print'}>{(value.rdv!='ANNULLER')&&<PDFGenerator user={user} rendezVousDTO={value} />}</td>
                                                        <td key={(index+1)+'td.option'}>
                                                            {(value.rdv == 'PRIS') && <Button onClick={() => {
                                                                annuller(value.id)
                                                            }}>Annulle</Button>}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    }
                                    {
                                        rdv.length == 0 && loading && <h3>Patientez ...</h3>
                                    }
                                    {
                                        rdv.length == 0 && !loading && <h3>Aucun rendez-vous trouvee</h3>
                                    }

                                    <>

                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>;
}